import { FaMapMarkerAlt } from "react-icons/fa";
import { openPopupWidget } from "react-calendly";
import styles from "./HomeLandingPage.module.css"

export default function LandingWorkers({workers, category}) {
  if(!category) return <></>;

  const Badge = ({name}) => {
    return(
      <span className="badge badge-primary">{name}</span>
    )
  }

  const workerElements = workers.map(worker => {
    const exp = worker.years_of_experience + " " + (worker.years_of_experience === 1 ? "year" : "years");

    return(
      <div className="col-lg-4 d-flex">
        <div className="box" style={{position: "relative", paddingBottom: 70}}>
          <div className={styles["profile-header"]}>
            <div className={styles["profile-image"]}>
              <span className={styles["profile-circle"]}>{worker.fname[0]}{worker.lname[0]}</span>
            </div>
            <p className={styles["profile-name"]}>{worker.fname} {worker.lname}</p>
            <p className={styles["profile-title"]}>{worker.job_title}</p>
            <p className={styles["profile-location"]}><FaMapMarkerAlt/> {worker.location}</p>
          </div>
          <hr className={styles["hr"]}/>
          <div className={styles["profile-info"]}>
            <p className={styles["profile-label"]}>Experience</p>
            <p><Badge name={exp}/></p>
            <p className={styles["profile-label"]}>Work Types</p>
            <p>{worker.work_types ? worker.work_types.map(wtype => <Badge name={wtype}/>) : "N/A"}</p>
            <p className={styles["profile-label"]}>Industries</p>
            <p>{worker.industries ? worker.industries.map(industry => <Badge name={industry}/>) : "N/A"}</p>
            <p className={styles["profile-label"]}>Training</p>
            <p><Badge name={worker.training || "N/A"}/></p>
          </div>
          <button id={"worker-" + worker.identifier} className="btn btn-primary btn-block" style={{position: "absolute", left: 30, bottom: 30, width: "calc(100% - 60px)"}} onClick={() => openPopupWidget({url: 'https://calendly.com/meetladder/ladder-demo'})}>Get Connected</button>
        </div>
      </div>
    )
  })

  return(
    <>
      <div className={styles['workers-container']} id="workers">
        <div className="container">
          <h2 className={styles["how-title"]}>Featured {category}</h2>
          <p className={styles["how-subheader"]}>Like someone you see? Book a demo with us, and we’ll connect you with them.</p>
          <div className="row justify-content-center flex-fill">
            {workerElements}
          </div>
        </div>
      </div>
    </>
  )
}